<template>
    <div class="mt-[30px]">
        <div class="text-white flex justify-between mb-[20px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[15px] font-semibold">ІНФОРМАЦІЯ ПРО ГРАВЦЯ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>
        <div>
            <div class="student-data-table mt-4 mx-4 border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-6 py-1">
                <div class="flex justify-between border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg text-white">{{ store.student.name }}</div>
                    <div class="font-light text-sm text-[#FFBC00]">ім'я</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg text-white">{{ store.student.surname }}</div>
                    <div class="font-light text-sm text-[#FFBC00]">прізвище</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg text-white">{{ store.student.patronymic }}</div>
                    <div class="font-light text-sm text-[#FFBC00]">по батькові</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div v-if="store.student.name_eng?.length > 0" class="font-bold text-lg text-white">{{ store.student.name_eng }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-white">Ім'я англійською</div>
                    <div class="font-light text-sm text-[#FFBC00]">name</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div v-if="store.student.surname_eng?.length > 0" class="font-bold text-lg text-white">{{ store.student.surname_eng }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-white">Фамілія англійською</div>
                    <div class="font-light text-sm text-[#FFBC00]">surname</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div v-if="store.student.passport?.length > 0" class="font-bold text-lg text-white">{{ store.student.passport }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-white">Номер паспорту</div>
                    <div class="font-light text-sm text-[#FFBC00]">паспорт</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div v-if="store.student.id_number?.length > 0" class="font-bold text-lg text-white">{{ store.student.id_number }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-white">ID номер</div>
                    <div class="font-light text-sm text-[#FFBC00]">ID код</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div v-if="store.student.passport_end_date?.length > 0" class="font-bold text-lg text-white">{{ store.student.passport_end_date.split('T')[0].split('-').reverse().join('.') }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-white">12.12.2030</div>
                    <div class="font-light text-sm text-[#FFBC00]">дата закінчення паспорту</div>
                </div>
                <div v-for="(patron, id) in store.student.patrons">
                    <div v-if="patron.name.length > 0" class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                        <div v-if="patron.name.length > 0" class="font-bold text-lg truncate w-[50%] text-white">{{ patron.name }}</div>
                        <div v-else class="font-bold text-lg opacity-60 text-white">Ім'я</div>
                        <div class="font-light text-sm text-[#FFBC00]">відпов. особа #{{ id + 1 }}</div>
                    </div>
                    <div v-if="patron.name.length > 0" class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                        <div v-if="patron.phone_number.length > 0" class="font-bold text-lg text-white">{{ patron.phone_number }}</div>
                        <div v-else class="font-bold text-lg opacity-60 text-white">Телефон</div>
                        <div class="font-light text-sm text-[#FFBC00]">телефон в.о. #{{ id + 1 }}</div>
                    </div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div v-if="store.student.birthday?.length > 0" class="font-bold text-lg text-white">{{ store.student.birthday.split('T')[0].split('-').reverse().join('.') }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-white">12.12.2030</div>
                    <div class="font-light text-sm text-[#FFBC00]">день народження</div>
                </div>
            </div>
            <div class="border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-3   py-2 mx-4 mt-2">
                <div>
                    <div class="border-b-[0.2px] border-[#A5A3A3] border-opacity-25 grid grid-cols-12 w-full">
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4">
                            <span v-if="store.student.height > 0" class="font-bold text-lg text-white">{{ store.student.height }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-white">0.00</span>
                            <br>
                            <span class="text-xs font-light text-[#FFBC00]">зріст, см</span>
                        </div>
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4">
                            <span v-if="store.student.weight > 0" class="font-bold text-lg text-white">{{ store.student.weight }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-white">0.00</span>
                            <br>
                            <span class="text-xs font-light text-[#FFBC00]">вага, кг</span>
                        </div>
                        <div class=" pl-3 py-2 text-center col-span-4">
                            <span v-if="store.student.foot_size > 0" class="font-bold text-lg text-white">{{ store.student.foot_size }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-white">0.00</span>
                            <br>
                            <span class="text-xs font-light text-[#FFBC00]">розмір ноги</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-12">
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4">
                            <span v-if="store.student.leading_foot?.length > 0" class="font-bold text-lg text-white">{{ store.student.leading_foot }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-white">Ліва</span>
                            <br>
                            <span class="text-xs font-light text-[#FFBC00]">нога</span>
                        </div>
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4">
                            <span v-if="store.student.breast_volume > 0" class="font-bold text-lg text-white">{{ store.student.breast_volume }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-white">0.00</span>
                            <br>
                            <span class="text-xs font-light text-[#FFBC00]">об'єм гр.</span>
                        </div>
                        <div class=" pl-3 py-2 text-center col-span-4"><span class="font-bold text-lg text-white">{{ store.startTrainingDate }}</span><br><span class="text-xs font-light text-[#FFBC00]">старт в школі</span></div>
                    </div>
                </div>
                <div>
                    <div @click="$router.push('/stats')" class="text-[#FFBC00] flex items-center justify-end ">
                        <div class="text-[14px]">ДИНАМІКА</div>
                        <div class="ml-[5px] mt-[1px] ">
                            <svg class="h-[14px]" width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2L8.43043 8.17424C9.18986 8.90341 9.18986 10.0966 8.43043 10.8258L2 17" stroke="#FFBC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="$router.push('/edit-data')" class="border-[#D8E2DC] border-[1px] rounded-[9px] text-[#D8E2DC] px-3 py-2 w-[92%] text-base font-semibold flex items-center align-middle justify-between mt-5 mx-auto">
                <div class="w-full">Редагувати</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { onMounted } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'

    export default {
        name: 'student-data-block',

        setup() {
            const store = useMainStore()

            return {
                store
            }
        }
    }
</script>
