<template>
    <div class="">
        <div class="w-full h-[14px] relative py-[2px] border-[1px] flex border-[#4E5568] rounded-full">
            <div class="block bg-[#5AC47D] ml-[1px] relative bottom-[1px] h-[10px] trainings-percent-width rounded-l-full"></div>
            <div class="w-px h-[15px] bg-[#FFF]">
            </div>
            <div class="absolute text-[12px] top-[18px] left-gap text-[#FFF]">
                {{ store.trainingsAttendedProcent > 0 ? store.trainingsAttendedProcent.toFixed(0) : 0  }}%
            </div>
        </div>
        <!-- <div 
            class="w-full text-[12px] text-[#A1D9F7]"
            :class="{
                'text-end' : isNaN(store.attendedTrainingsPercent) || store.attendedTrainingsPercent < 50,
                'text-start' : store.attendedTrainingsPercent >= 50,
            }"    
        >
            відвідування
        </div> -->
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'TrainingAttending',

        setup(props, { emit }) { 
            const store = useMainStore()
            const trainingPercent = ref(`${store.trainingsAttendedProcent - 3.2 > 0 ? store.trainingsAttendedProcent - 3.2 : 0}%`)
            const leftGap = ref(`${store.trainingsAttendedProcent - 12 > 0 ? store.trainingsAttendedProcent - 12 : 0 }%`)

            onMounted(() => {
                console.log('store.attendedTrainingsPercent', store.trainingsAttendedProcent)
            })

            return {
                store,
                leftGap,
                trainingPercent
            }
        }
    }
</script>

<style scoped>

    .trainings-percent-width {
        width: v-bind(trainingPercent);
    }

    .left-gap {
        left: v-bind(leftGap);
    }

</style>
