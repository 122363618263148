<template>
    <div class="mt-[30px]">
        <div class="text-white flex justify-between mb-[20px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[15px] font-semibold">ФІНАНСИ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>

        <div class="text-end mr-3 text-[#FFF]">
            оплати <b>{{ currentYear }}</b>
        </div>

        <div class="h-[70px] mx-3 flex justify-between align-bottom items-end">
            <div v-for="month in incomesByMonth" class="flex flex-col justify-center items-center w-[20px]">
                <div class="text-[#FFF] text-xs">
                    {{ month.incomes > 0 ? month.incomes : 0 }}
                </div>
                <div class="w-[20px]  bg-[#D8E2DC]" :style="{ height : `${month.barHeight > 0 ? month.barHeight : 2 }px` }"></div>
                <div class="text-xs text-[#FFF]">
                    {{ month.month }}
                </div>
            </div>
        </div>

        <button @click="$router.push('/income-history')" class="border-[#D8E2DC] border-[1px] text-[#D8E2DC] rounded-[9px] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Мої оплати</div>
        </button>

        <button @click="$router.push('/payment-view')" class="bg-[#D8E2DC] rounded-[9px] text-black px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Сплатити</div>
        </button>

    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'

    export default {
        name: 'finance-block',

        props: {
            studentIncomes: {
                type: Array,
            }
        },

        setup(props) {
            const currentYear = ref(new Date().getFullYear())
            const incomesByMonth = ref([])

            const mapIncomesMounthPerAmount = (studentIncomes) => {
                let incomesWithMounth = []
                studentIncomes.forEach(income => {
                    const incomeDate = new Date(income.date)
                    const incomeMonth = incomeDate.toLocaleString('uk', { month: 'short' })
                    const incomeYear = incomeDate.getFullYear()

                    incomesWithMounth.push({
                        month: incomeMonth,
                        year: incomeYear,
                        amount: income.amount
                    })
                })
                return incomesWithMounth
            }

            const buildDiagram = () => {
                incomesByMonth.value = []
                const mappedIncomes = mapIncomesMounthPerAmount(props.studentIncomes)
                const incomesToMonth = []

                for (let i = 0; i < 12; i++) {
                    const currentMonth = new Date(currentYear.value, i, 1).toLocaleString('uk', { month: 'short' })
                    const currentMonthIncomes = mappedIncomes.filter(item => item.month === currentMonth && item.year === currentYear.value) 
                    
                    let currentIncome = 0

                    if (currentMonthIncomes) {
                        currentMonthIncomes?.forEach(income => {
                            currentIncome += income.amount
                        })
                    }
                    incomesToMonth.push(currentIncome)
                }

                const maxIncome = Math.max(...incomesToMonth)

                for (let i = 0; i < 12; i++) {
                    const currentMonth = new Date(currentYear.value, i, 1).toLocaleString('uk', { month: 'short' })
                    const currentMonthIncomes = mappedIncomes.filter(item => item.month === currentMonth && item.year === currentYear.value) 
                    
                    let currentIncome = 0

                    if (currentMonthIncomes) {
                        currentMonthIncomes?.forEach(income => {
                            currentIncome += income.amount
                        })
                    }
                    
                    const percentOfMaxIncome = (currentIncome * 100) / maxIncome
                    const heightOfBar = (percentOfMaxIncome * 30) / 100
                    incomesByMonth.value.push({
                        month: currentMonth,
                        incomes: currentIncome,
                        barHeight: heightOfBar
                    })
                }
            }

            const genInvoce = () => {
                window.open('https://secure.wayforpay.com/page?vkh=63d3cb16-7eb4-488b-834f-1df322d1dba8', '_blank', 'noreferrer')
            }

            watch(() => props.studentIncomes, () => {
                if (props.studentIncomes.length != 0) {
                    buildDiagram()
                }
            })

            onMounted(() => {
                buildDiagram()
            })

            return {
                genInvoce,
                currentYear,
                incomesByMonth
            }
        }
    }
</script>
