<template>
    <div class="container">
        <div class="balance-block">
            <div class="label-text">
                тариф
            </div>
            <div class="balance-text">
                {{ lastIncome.tariff_name ? `"${lastIncome.tariff_name}"` : "Відсутній" }}
            </div>
        </div>
        <div class="active-to-block">
            <div class="label-text">
                активний до
            </div>
            <!-- <div class="date-text mr-[15px]">
                {{ activeToDate }} 
            </div> -->

            <!-- <div v-if="activeToDate == 'борг'" class="font-normal text-[22px] mt-[-6px] text-center text-[#CA3F79]">
                оплатіть тариф
            </div> -->
            <div class="date-text mr-[15px]" :class="{'text-[#5AC47D]' : activeToDateObject > currentDate, 'text-[#CA3F79]' : activeToDateObject <= currentDate}">
                <b>{{ formatDate(activeToDate) }}</b>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from '../../store/mainStore'
    import { ref, onBeforeMount, watch } from 'vue'

    export default {
        name: 'StudentFinanceData',

        props: {
            lastIncome: {
                type: Object,
                default: ''
            }
        },

        setup() {
            const store = useMainStore()

            const activeToDate = ref(store.student.date_active_to)


            const activeToDateObject = ref(new Date(activeToDate.value))
            const currentDate = ref(new Date())

            const formatDate = (date) => {
                const newDate = new Date(date)
                return newDate.toLocaleDateString().split('T')[0].split('-').reverse().join('.')
            }

            // if (store.studentBalance >= 0) {
            //     const currentDate = new Date()
            //     const date = new Date(`${currentDate.getMonth() + 2}.01.${currentDate.getFullYear()}`)
            //     console.log('date month', date.getMonth())
            //     activeToDate.value = date.toLocaleDateString().split('T')[0].split('-').reverse().join('.')
            // } else {
            //     const currentDate = new Date()
            //     const date = new Date(`${currentDate.getMonth()}.01.${currentDate.getFullYear()}`)
            //     console.log('date month', date.getMonth())
            //     activeToDate.value = date.toLocaleDateString().split('T')[0].split('-').reverse().join('.')
            // }

            return {
                store,
                activeToDate,
                formatDate,
                activeToDateObject,
                currentDate,
            }
        }
    }
</script>

<style lang="scss" scoped>


    @media (min-width: 0px) and (max-width: 1800px) {
        .container {
            @apply flex justify-between;
        }

        .balance-block {
            @apply rounded-[10px] px-[8px] mr-[18px] w-[218px] h-[56px];
        }

        .active-to-block {
            @apply relative rounded-[10px] px-[8px] w-[225px] h-[56px];
        }
        
        .label-text {
            @apply text-[#FFF] mt-[5px] mb-[2px] text-[14px];
        }

        .balance-text {
            @apply font-bold text-[28px] mt-[-10px] text-center text-[#CA3F79];
        }

        .date-text {
            @apply font-bold text-[28px] mt-[-10px] text-end;
        }
    }

    @media (min-width: 1800px) and (max-width: 8000px) {
        .container {
            @apply flex justify-between h-[58px] w-[582px];
        }

        .balance-block {
            @apply rounded-[10px] px-[8px] mr-[18px] w-[228px] h-[56px];
        }

        .active-to-block {
            @apply relative rounded-[10px] px-[8px] w-[225px] h-[56px];
        }
        
        .label-text {
            @apply text-[#FFF] mt-[3px] mb-[2px] text-[14px];
        }

        .balance-text {
            @apply font-bold text-[28px] mt-[-10px] text-center text-[#CA3F79];
        }

        .date-text {
            @apply font-bold text-[28px] mt-[-10px] text-end;
        }
    }

</style>
