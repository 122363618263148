<template>
    <div class="w-[75%] flex justify-center mt-[15px] relative select-none">
        <div class="w-max ">
            <div class="text-white font-semibold text-[24px] text-center">{{ store.student.group_name.length > 0 ? store.student.group_name : '--' }}</div>
            <div class="relative w-[30.2vw] h-max">
                <img src="../../assets/field.svg" class="h-full w-full">

                <div class="absolute top-[23%] left-[50%] translate-x-[-50%]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ЦФ')" 
                            class="rounded-full w-[1vw] z-40 h-[1vw] bg-[#CA3F79] cursor-pointer border-[0.2vw] border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ЦФ',
                            }"    
                        ></div>
                        <div v-if="ampluaList['ЦФ'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ЦФ'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[43%] left-[5vw]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ЛП')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ЛП',
                            }"    
                        ></div>
                        <div v-if="ampluaList['ЛП'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ЛП'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[47%] left-[50%] translate-x-[-50%]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ЦП')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ЦП',
                            }"    
                        ></div>
                        <div v-if="ampluaList['ЦП'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ЦП'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[43%] right-[18%]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ПП')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ПП',
                            }"    
                        ></div>
                        <div v-if="ampluaList['ПП'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ПП'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="absolute top-[63%] right-[0]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ПЗ')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ПЗ',
                            }"    
                        ></div>
                        <div v-if="ampluaList['ПЗ'].status" class="w-[7.5vw] z-50 h-max relative mt-[0.1vw] right-[3.3vw]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ПЗ'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[68%] left-[50%] translate-x-[-50%]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ЦЗ')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ЦЗ',
                            }"       
                        ></div>
                        <div v-if="ampluaList['ЦЗ'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ЦЗ'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[63%] left-[6vw]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ЛЗ')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ЛЗ',
                            }"    
                        ></div>
                        <div v-if="ampluaList['ЛЗ'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ЛЗ'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[86%] left-[50%] translate-x-[-50%]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('ВР')" 
                            class="rounded-full w-[1vw] z-30 h-[1vw] bg-[#CA3F79] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'ВР',
                            }"   
                        >
                        </div>
                        <div v-if="ampluaList['ВР'].status" class="w-[7.5vw] z-50 h-max absolute mt-[0.1vw] left-[50%] translate-x-[-50%]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['ВР'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute top-[0.3vw] right-[0.3vw]">
                    <div class="relative">
                        <div 
                            @click="changeAmpluaStatus('undefined')" 
                            class="bg-[#CA3F79] rounded-full w-[1vw] z-30 h-[1vw] border-[0.2vw] cursor-pointer border-[#02130E]"
                            :class="{
                                'circle' : store.student.amplua == 'undefined',
                            }"    
                        ></div>
                        <div v-if="ampluaList['undefined'].status" class="w-[7.5vw] mt-[0.1vw] z-50 h-max absolute right-[0.2vw]">
                            <div class="players-list h-[100px] w-full overflow-y-auto">
                                <div v-for="player in ampluaList['undefined'].players" class="flex w-[90%] mx-auto relative mb-1">
                                    <div class="bg-[#D8E2DC] w-[20%] truncate text-center text-[14px] px-1 font-semibold text-[#02130E]">
                                        {{ player.number }}
                                    </div>
                                    <div class="bg-[#02130E] w-[80%] truncate text-start text-[14px] px-2 text-[#FFF]">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'TeamField',

        setup() {
            const ampluaList = ref({
                'ВР' : {
                    status: true,
                    players: []
                },
                'ЦЗ' : {
                    status: true,
                    players: []
                },
                'ЛЗ' : {
                    status: true,
                    players: []
                },
                'ПЗ' : {
                    status: true,
                    players: []
                },
                'ЦП' : {
                    status: true,
                    players: []
                },
                'ПП' : {
                    status: true,
                    players: []
                },
                'ЛП' : {
                    status: true,
                    players: []
                },
                'ЦФ' : {
                    status: true,
                    players: []
                },
                'undefined' : {
                    status: true,
                    players: []
                }
            })
            const store = useMainStore()
            const router = useRouter()

            const changeAmpluaStatus = (amplua) => {
                // for (let key in ampluaList.value) {
                //     if (key !== amplua)
                //         ampluaList.value[key].status = false
                // }
                ampluaList.value[amplua].status = !ampluaList.value[amplua].status
            }

            onMounted(() => {
                try {
                    store.groupStudents.forEach((student) => {
                        if (Object.keys(ampluaList.value).includes(student.amplua)) {
                            ampluaList.value[student.amplua].players.push({
                                'name' : student.surname + ' ' + student.name.charAt(0) + '.',
                                'number' : student.player_number
                            })
                        } else {
                            ampluaList.value['undefined'].players.push({
                                'name' : student.surname + ' ' + student.name.charAt(0) + '.',
                                'number' : student.player_number
                            })
                        }
                    })
                } catch (e) {
                    router.push('/')
                }
            })

            return {
                ampluaList,
                changeAmpluaStatus,
                store
            }
        }
    }
</script>

<style lang="scss" scoped>
    .players-list::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .players-list::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .players-list::-webkit-scrollbar-thumb {
        background-color: #A1D9F7;    /* цвет бегунка */
        border-radius: 20px;
    }

    .players-list {
        animation-iteration-count: 1;
    }

    .circle {
        animation: pulse 1.5s infinite;
    }
    
    .circle > * {
        pointer-events: none;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.8);
            opacity: 0.8;
        }
        50% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(0.8);
            opacity: 0.8;
        }
    }

</style>