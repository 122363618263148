<template>
    <div class="bg-[#02130E] z-30 h-[90%] pt-[10px] rounded-[10px] absolute flex flex-col items-center">
        <div v-if="loader" class="absolute left-[50%] translate-x-[-50%] bg-gray-300 cropper-width h-[91%] opacity-25 z-60"></div>
        <div v-if="loader" class="w-5 h-5 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute top-[50%] left-[50%] -mt-5 -ml-[10px] z-50"></div>
		<cropper
            class="h-full -z-30 cropper-width"
			ref="cropper"
			:src="image.src"
		/>
        <button @click="crop" class="bg-[#5AC47D] rounded-[9px] text-black px-3 py-2 w-[92%] text-base font-semibold flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Зберегти</div>
        </button>
	</div>
</template>

<script>
    import { ref } from 'vue'
    import { sendPhotoToRemoveBg } from '../../services/apiRequests'

    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css'

    export default {

        props: {
            image: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const cropper = ref(null)
            const loader = ref(false)
            const screenWidth = ref(window.screen.width + 'px')

            const crop = () =>{
                loader.value = true
                const { canvas } = cropper.value.getResult();
                canvas.toBlob((blob) => {
                    sendPhotoToRemoveBg(blob).then(res => {
                        const blob_file = new Blob([res], {type: "image/png"})
                        
                        var reader = new FileReader()
                        reader.readAsDataURL(blob_file)
                        
                        reader.onloadend = () => {  
                            emit('processedImage', reader.result)
                            loader.value = false
                        }
                    })
                }, props.image.type);
            }

            return {
                crop,
                cropper,
                loader,
                screenWidth
            }
        },

        components: {
            Cropper,
        },

    }
</script>

<style lang="scss" scoped>

    .cropper-width {
        width: v-bind(screenWidth);
    }

</style>