<template>
    <transition name="black-bg">
        <div v-if="testFlag" class="bg-black bg-opacity-25 fixed w-full z-10 h-full"></div>
    </transition>
    <transition name="frame">
        <div v-if="testFlag" class="w-full h-[300px] fixed bottom-0 z-20 rounded-t-[10px] bg-[#02130E]">
            <div class="flex justify-end pt-3 pr-3">
                <img @click="closeView" src="../../assets/cross_icon.svg">
            </div>
            <div v-if="alertType.length == 0">
                <div>
                    <div class="text-white text-center text-xl font-bold">
                        Налаштування сповіщень
                    </div>
                    <div class="text-white text-center text-sm mt-1">
                        Виберіть спосіб отримання сповіщень
                    </div>
                </div>
                <div class="px-4 mt-4">
                    <div @click="alertType = 'telegram'" class="bg-[#5AC47D] flex justify-center items-center mb-3 px-3 w-full h-[40px] rounded-md">
                        <div v-if="store.isBotSubscribed"><img src="../../assets/check_icon.svg" class="w-4"></div>
                        <div class="font-bold text-[16px] text-[#000] text-center w-full">Telegram</div>
                    </div>
                    <div @click="alertType = 'push'" v-if="pushAlertsAvailable" class="bg-[#5AC47D] flex justify-center items-center px-3 w-full h-[40px] rounded-md">
                        <div v-if="pushSubscription"><img src="../../assets/check_icon.svg" class="w-4"></div>
                        <div class="font-bold text-[16px] text-[#000] text-center w-full">Пуш-повідомлення</div>
                    </div>
                </div>
                <!-- <div class="px-4 fixed bottom-6 w-full">
                    <div class="bg-[#5AC47D] flex justify-center items-center w-full h-[40px] rounded-md">
                        <div class="font-bold text-[16px] text-[#FFF]">Зберегти</div>
                    </div>
                </div> -->
            </div>
            <div v-if="alertType == 'telegram'" class="px-2">
                <div class="w-full flex justify-center">
                    <img src="../../assets/robot_icon.svg" class="w-9">
                </div>
                <div class="text-center bg-[#5AC47D] bg-opacity-25 mt-4 py-2 rounded-lg font-bold text-[15px] text-[#5AC47D]">
                    Перейдіть до бота та натисніть кнопку "Почати"
                </div>
                <div @click="linkToBot" class="bg-[#5AC47D] flex justify-center items-center mt-3 px-3 w-full h-[40px] rounded-md">
                    <!-- <div class="w-4 mx-2"></div> -->
                    <div class="font-bold text-[16px] text-[#000] text-center">Лінк на бота</div>
                    <!-- <div class="flex items-center"><img src="../../assets/robot_icon.svg" class="w-5 mx-2 mt-1"></div> -->
                </div>
                <div v-if="clickedbotLink">
                    <div class="text-center bg-[#5AC47D] bg-opacity-25 mt-3 py-2 rounded-lg font-bold text-[15px] text-[#5AC47D]">
                        Тепер клікніть "Перевірити"
                    </div>
                    <div @click="checkBot" class="bg-[#5AC47D] mt-3 flex justify-center items-center w-full h-[40px] rounded-md">
                        <div class="font-bold text-[16px] text-[#FFF]">Перевірити</div>
                    </div>
                </div>
            </div>
            <div v-if="alertType == 'push'" class="px-2">
                <div class="w-full flex justify-center">
                    <img src="../../assets/sent_icon.svg" class="w-9">
                </div>
                <div v-if="loadingSpinnerFlag" class="bg-[#02130E] fixed w-full h-full z-[120] left-[50%] translate-x-[-50%] mt-7">
                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] mx-auto border-t-transparent"></div>
                </div>
                <div v-else @click="setPushMessages" class="bg-[#A1D9F7] flex justify-center items-center mt-3 px-3 w-full h-[40px] rounded-md">
                    <div class="w-4 mx-2"></div>
                    <div class="font-bold text-[16px] text-[#000] text-center">Активувати пуш-повідомлення</div>
                    <!-- <div class="flex items-center"><img src="../../assets/robot_icon.svg" class="w-5 mx-2 mt-1"></div> -->
                </div>
            </div>

        </div>
    </transition>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { checkBotSubscribe, addPushTokenToStudent, isUserSubscribed } from '@/mobileApp/services/apiRequests'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { base64UrlToUint8Array } from './encryption/helpers.js'
    import { initializeApp } from "firebase/app";
    import { getMessaging, getToken, onMessage } from "firebase/messaging";

    export default {
        name: 'alerts-settings',

        setup(props, { emit }) {
            const alertType = ref('')
            const store = useMainStore()

            const clickedbotLink = ref(false)
            const permissionGranted = ref(false)
            const pushSubscription = ref(false)
            const pushAlertsAvailable = ref(false)

            const loadingSpinnerFlag = ref(false)

            const linkToBot = () => {
                window.open(process.env.VUE_APP_BOT_ALERTS_URL + `?start=${store.student.student_id}`, '_parent')
                clickedbotLink.value = true
            }

            const setPushMessages = async () => {
                loadingSpinnerFlag.value = true
                const tokenPush = ref('')

                const firebaseConfig = {
                    apiKey: "AIzaSyADnyTft6NzTINwARsNciEfVzX9P_NBU2M",
                    authDomain: "diem-front-student.firebaseapp.com",
                    projectId: "diem-front-student",
                    storageBucket: "diem-front-student.appspot.com",
                    messagingSenderId: "630220681480",
                    appId: "1:630220681480:web:4f348651a8194567677cbd",
                    measurementId: "G-Z4WJ10RFRV"
                };

                const app = initializeApp(firebaseConfig);

                const messaging = getMessaging();

                const APPLICATION_KEYS = {
                    publicKey: 'BJCBWIwIL5nEZ96V18emXQq5qBkjbJI8HIji5-nptsj-VY3aPmiSZ7n8LI4H5Wj9g7SKphnXU1rZX4L5wXUhGMo',
                    privateKey: '-DybuTtj6NxdWPaghxpD44S1ZQ6CNEw6MGuQ2py8Z3c',
                };

                const publicApplicationKey = base64UrlToUint8Array(APPLICATION_KEYS.publicKey)
                if ('serviceWorker' in navigator && 'PushManager' in window) {
                    navigator.serviceWorker.ready
                        .then(registration => registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: publicApplicationKey }))
                        .then(subscription => {
                            permissionGranted.value = true; 
                        })
                        .catch(error => {
                            console.error('Ошибка при получении разрешения на Push-уведомления:', error);
                        });

                    getToken(messaging, { vapidKey: 'BJCBWIwIL5nEZ96V18emXQq5qBkjbJI8HIji5-nptsj-VY3aPmiSZ7n8LI4H5Wj9g7SKphnXU1rZX4L5wXUhGMo' }).then(async (currentToken) => {
                        if (currentToken) {
                            await addPushTokenToStudent(currentToken, store.student.student_id)
                            console.log("Token is:",currentToken);
                            localStorage.setItem('push_subscription', '1')
                            pushSubscription.value = true
                            loadingSpinnerFlag.value = false
                            alertType.value = ''
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                        }).catch((err) => {
                            console.log('An error occurred while retrieving token. ', err);
                        }
                    );
                } else {
                    console.warn('Push-уведомления не поддерживаются в вашем браузере.');
                }
            }

            const checkBot = async () => {
                const res = await checkBotSubscribe(store.student.student_id)
                if (res.data.status == 'success') {
                    store.isBotSubscribed = true
                    alertType.value = ''
                }
            }

            const testFlag = ref(false)

            const closeView = () => {
                testFlag.value = false
                setTimeout(() => {
                    emit('close')
                }, 300)
            }

            onMounted(async () => {

                if (localStorage.getItem('push_subscription') == '1') {
                    console.log('token exist')
                    pushSubscription.value = true
                }

                if ('serviceWorker' in navigator && 'PushManager' in window) {
                    pushAlertsAvailable.value = true
                }

                setTimeout(() => {
                    testFlag.value = true
                }, 1)
            })

            return {
                alertType,
                clickedbotLink,
                linkToBot,
                checkBot,
                store,
                testFlag,
                closeView,
                setPushMessages,
                pushSubscription,
                pushAlertsAvailable,
                loadingSpinnerFlag
            }
        }
    }
</script>

<style lang="scss">
    @keyframes slide {
        0% {
            bottom: -300px;
        }
        100% {
            bottom: 0px;
        }
    }

    @keyframes showBg {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
        
    .frame-enter-active {
        animation: slide 0.3s ease-in-out forwards;
    }

    .black-bg-enter-active {
        animation: showBg 0.3s ease-in-out forwards;
    }

    .frame-leave-active {
        animation: slide 0.3s ease-in-out reverse forwards;
    }

    .black-bg-leave-active {
        animation: showBg 0.3s ease-in-out reverse forwards;
    }
</style>