<template>
    <div style="background-color: rgba(29, 29, 29, .35);" class="w-[100%] backdrop-blur-[20px] ml-[-10px] top-[-30px] h-full opacity-30 absolute z-20"></div>
    <div class="bg-[#02130E] shadow-md w-[512px] absolute top-[50%] border-[1px] border-[#252526] border-opacity-20 left-[50%] ml-[128px] translate-x-[-50%] translate-y-[-50%] z-30 px-[18px] pt-[6px] pb-[8px] rounded-[10px]">
        <!-- 
            border-[#11204D]
            <div class="absolute top-[10px] right-[10px]">
            <button class="float-right relative" @click="$emit('close')">
                <img src="@/assets/icons/cross_icon.svg" class="w-3 h-3">
            </button>
        </div> -->
        <div class="text-[#fff] font-semibold text-center mb-[-5px] pb-[3px] pt-[3px]">
            <div class="relative top-[1px]">
                {{ `${day.dayName[0].toUpperCase() + day.dayName[1]}.  ${date}` }}
            </div>
            
        </div>

        <div class="w-full border-t-[1px] mt-[12px] relative">
            <div class="scrolltab h-[200px]  mb-2 mt-[10px] pt-[7px] overflow-auto">
                
                <div v-for="training in allTrainings">
                    <button 
                        class="flex items-center mt- flex-shrink-0 h-5 mb-3 text-[13px] cursor-default w-full"
                    >
                        <div class="mt-[3px] ml-[1px] mr-[3px]">
                            <img v-if="training.type == 'training'" src="../../assets/green_ball.svg" class="h-[20px] relative right-[2px]">
                            <img v-else-if="training.type == 'gym'" src="../../assets/red_dumbbell.svg" class="h-[20px]">
                            <img v-else-if="training.type == 'competition'" src="../../assets/gold_cup.svg" class="h-[20px]">
                            <img v-else src="../../assets/green_ball.svg" class="h-[20px] relative right-[2px]">
                        </div>
                        <div class="w-[48px] ml-[5px] bg-[#474849] border-[1px] border-[#8C8C8C] rounded-[4px] py-1 bg-opacity-20 text-center text-[#fff] leading-none">{{ training.start_time }}</div>
                        <div class=" text-[#fff] mx-[6px] flex justify-center">
                            <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.940665 8.94267L0 8L3.52599 4.47133L0 0.942666L0.943331 0L4.46666 3.52867C4.71662 3.7787 4.85704 4.11778 4.85704 4.47133C4.85704 4.82488 4.71662 5.16396 4.46666 5.414L0.940665 8.94267Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="w-[48px] bg-[#474849] border-[1px] border-[#8C8C8C] rounded-[4px] py-1 bg-opacity-20 text-center text-[#fff] leading-none">{{ training.end_time }}</div>
                        <div class="w-[80px] bg-[#474849] border-[1px] border-[#8C8C8C] rounded-[4px] py-1 bg-opacity-20 mx-2 text-center font-bold text-[#fff] leading-none truncate">{{ training.group_label }}</div>
                        <div class="w-[200px] bg-[#474849] border-[1px] border-[#8C8C8C] rounded-[4px] py-1 bg-opacity-20 text-start px-1 text-[#fff] leading-none truncate">{{ training.location_label }}</div>
                        <div class="ml-[10px] flex w-[25px] justify-end h-full">
                            <div class="">
                                <img @click="$emit('setTrainingToView', training)" src="../../assets/green_pen.svg" class="h-[22px] relative left-[1px] cursor-pointer">
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <!-- <div class="mt-5 w-full">
            <button @click="$emit('addTraining', day.dateObj)" class="text-[#142552] bg-[#ABD3EC] text-center w-full rounded-md py-1 hover:bg-opacity-90">Додати тренування</button>
        </div> -->
        <div class="w-[55%] mx-auto">
            <button @click="$emit('close')" class="mt-[20px] text-[#fff] bg-[#5AC47D] relative bottom-[8px] text-[13px] text-center font-bold w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
        </div>

    </div>
</template>

<script>
    import { ref, computed, watch } from 'vue'

    export default {
        name: 'full-day-view',

        props: {
            day: {
                type: Date,
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.day.dateObj)
            const settedYear = settedDate.getFullYear() >= 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 >= 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() >= 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const date = ref(`${settedDay}.${settedMonth}.${settedYear}`)

            const allTrainings = computed(() => {
                return props.day.trainings
            })

            const deleteTraining = async (id) => {
                const response = await deleteTrainingById(id)
                if (response.status === 200) {
                    emit('deleteTraining', id)
                }
            }

            return {
                date,
                deleteTraining,
                allTrainings
            }
        }
    }
</script>

<style>

    .scrolltab::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: #ededed;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>
