<template>
    <div class="bg-[#000] h-full flex flex-col items-center z-50" :key="updateKey">
        <div class="w-[110px] h-[98px] select-none flex justify-center items-center rounded-full mt-[33px] mb-[29px]">
            <img src="../assets/minaj_icon.svg" class="w-[100px] h-[100px]">
        </div>
        <!-- <div class="w-[80%] bg-white h-[0.5px] rounded-lg select-none opacity-25"></div> -->
        <div class="w-[230px] ml-auto mt-[47px]">
            <div 
                v-for="tab in menuTabs" 
                @click="chooseInMenu(tab)" 

                class="text-white text-center py-[10px] mb-[15px] cursor-pointer text-sm select-none items-center"

                :class="
                    {
                        'bg-gradient-to-l from-[#D9D9D93D]' : route.path == tab.path,
                    }
                    " 
            >
                <div class="flex items-center ml-[30px]">
                    <img :src="tab.icon" class="ml-[5px] mr-[20px] w-[22px]">
                    <span class="font-base text-[16px] font-semibold">
                        {{ tab.name }}
                    </span>
                </div>
                <div v-if="tab?.innerPages && tab?.innerPagesView" class="absolute mt-[3px]">
                    <div 
                        v-for="innerTab in tab?.innerPages" 
                        class="flex pl-[43px] mt-[5px] py-[15px] w-[170px]"
                        @click="$router.push(innerTab.path)"

                        :class="
                        {
                            'bg-gradient-to-l from-[#D9D9D93D]' : route.path.includes(innerTab.path),
                        }" 
                    >
                        <span class="font-base text-[1.2vw]">
                            {{ innerTab.name }}
                        </span> 
                    </div>
                </div>
            </div>
            <div @click="logout" class="mt-[160px] mr-[16px] flex justify-center cursor-pointer">
                <img src="../assets/logout_icon.svg" class="mr-[5px]">
                <div class="text-white font-semibold">Вийти</div> 
            </div>
        </div>
        <!-- <div class="mt-[75px]">
            <div class="flex items-center">
                <img class="cursor-pointer" src="../../assets/sidebar_icons/sun_icon.svg">
                <img class="mx-[8px]" src="../../assets/sidebar_icons/arrow.svg">
                <img class="cursor-pointer" src="../../assets/sidebar_icons/moon_icon.svg">
            </div>
        </div> -->

    </div>
</template>

<script>
    import { ref, onBeforeMount, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { useRoute, useRouter } from 'vue-router'
    import { useMainStore } from '../store/mainStore'

    export default {
        setup() {
            const route = useRoute()
            const router = useRouter()
            const updateKey = ref(uuid.v1())
            const store = useMainStore()
            const groupPaymentStatus = ref('')
            const menuTabs = [
                {
                    path: '/',
                    name: 'Показники',
                    icon: require('../assets/sidebar_icons/dashboard_icon.svg')
                },
                {
                    path: '/team',
                    name: 'Команда',
                    icon: require('../assets/sidebar_icons/groups_icon.svg')
                },
                {
                    path: '/calendar',
                    name: 'Календар',
                    icon: require('../assets/sidebar_icons/calendar_icon.svg')
                },
                {
                    path: '/settings',
                    name: 'Налаштування',
                    icon: require('../assets/sidebar_icons/settings_icon.svg')
                }
            ]

            watch(() => store.group.payment_status, () => {
                console.log('store.group.payment_status ------', store.group.payment_status)
                console.log('groupPaymentStatus.value == paid ------ ', groupPaymentStatus.value == 'paid')
                groupPaymentStatus.value = localStorage.getItem('group_payment')

                if (store.group.payment_status == 'paid') {
                    menuTabs.splice(2, 0, {
                        path: '/finance',
                        name: 'Фінанси',
                        icon: require('../assets/sidebar_icons/finance_icon.svg')
                    }); 
                }
                updateKey.value = uuid.v4()
                
            })

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            const chooseInMenu = (tab) => {
                for (let i = 0; i < menuTabs.length; i++) {
                    menuTabs[i].innerPagesView = false
                }
                if (tab.innerPages) {
                    tab.innerPagesView = true
                    updateKey.value = uuid.v1()
                } else {
                    router.push(tab.path)
                }
            }

            onBeforeMount(() => {
                // console.log('store.group.payment_status ------ ', store.group.payment_status)

                // groupPaymentStatus.value = localStorage.getItem('group_payment')

                // if (groupPaymentStatus.value == 'free') {
                //     menuTabs.splice(2, 0, {
                //         path: '/finance',
                //         name: 'Фінанси',
                //         icon: require('../assets/sidebar_icons/finance_icon.svg')
                //     }); 
                // }
                // updateKey.value = uuid.v4()
            })

            return {
                route,
                router,
                menuTabs,
                logout,
                chooseInMenu,
                updateKey,
                store,
                groupPaymentStatus
            }
        }
    }
</script>

<style>

    .logo-place {
        box-shadow: inset -5px 0px 10px 3px rgba(0, 0, 0, .4);
    }

</style>