<template>
    <div v-if="store.student.group_name.length > 0" class="w-[350px]">
        <div class="flex items-center border-b-[1px] border-[#FFF] pb-[20px]">
            <div class="w-[74px] overflow-hidden h-[74px] flex items-end justify-center relative top-[6.5px]">
                <img v-if="store.trainer.photo" :src="apiUrl + 'static/' + store.trainer.photo" class="w-[84px]">
                <img v-else src="../../assets/student_photo_placeholder.svg" class="w-[74px]">
            </div>
            <div v-if="store.trainer.surname != '—'" class="relative top-[15px] h-max ml-[26px]">
                <div class="font-medium text-[24px] text-white">{{ store.trainer.name }}</div>
                <div class="font-medium leading-[32px] text-[24px] text-white">{{ store.trainer.surname }}</div>
            </div>
            <div v-else class="ml-[20px]">
                <div class="font-medium text-[24px] text-white">Нема</div>
                <div class="font-medium leading-[32px] text-[24px] text-white">тренера</div>
            </div>
            <div>
                <div class="flex flex-col items-center relative top-[6px] left-[31px]">
                    <img src="../../assets/logo.svg" class="w-[25px] mb-[6px]">
                    <div class="w-[80%] mx-auto h-[1px] bg-white mb-[10px] relative top-[2px]"></div>
                    <img src="../../assets/ukraine_flag.svg" class="w-[30px]">
                </div>
            </div>
        </div>
        <div class="h-[720px] group-scroll my-[5px] overflow-y-auto ml-[20px]">
            <div v-for="student in store.groupStudents" class="my-[5px] flex items-center justify-between  mx-auto">
                <div class="w-[20%]">
                    <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#D8E2DC] border-[2px]">
                        <img v-if="student.photo?.length > 0" :src="apiUrl + 'static/' + student.photo">
                        <div v-else class="w-full h-full bg-[#D8E2DC] bg-opacity-40 text-[#D8E2DC] text-[16px] font-semibold flex justify-center items-center">
                            {{ student.name[0] + student.surname[0] }}
                        </div>
                    </div>
                </div>
                <div class="text-white truncate w-[80%] text-[16px] text-start">{{ student.name + ' ' + student.surname }}</div>
            </div>
        </div>
    </div>
    <div v-else>
        <div>
            <div class="flex items-center justify-center text-[#5AC47D]">
                <span class="text-[41px]">4</span>
                <img src="../../assets/ball_icon.svg" class="w-[31px] mx-1">
                <span class="text-[41px]">4</span>
            </div>
        </div>
        <div class="text-[#5AC47D] text-[1vw]">Гравець не закріплений за жодною з груп</div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'GroupList',

        setup() {
            const apiUrl = ref(process.env.VUE_APP_API_URL)
            const store = useMainStore()
       
            return {
                store,
                apiUrl
            }
        }
    }
</script>

<style lang="scss" scoped>
    .group-scroll {
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(171, 211, 236, 0.2);
            border-radius: 20px;
        }
    }
</style>