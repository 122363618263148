<template>
    <full-day-view 
        v-if="fullDayViewFlag && !showFullTrainingFlag" 
        @setTrainingToView="training => { trainingToView = training;  showFullTrainingFlag = true}" 
        @addTraining="$event => {newTrainingFormFlag = true; currentNewTrainingDate = $event}" 
        @deleteTraining="$event => {fullDayViewDay.trainings = fullDayViewDay.trainings.filter(t => t.training_id !== $event); updateTrainings()}" 
        @close="fullDayViewFlag = false" :day="fullDayViewDay"
    />
    <full-training-view 
        v-if="showFullTrainingFlag" 
        @close="showFullTrainingFlag = false" 
        :training="trainingToView"
    />
    <div class="w-full" v-if="loading">
        <Header 
            class="fixed top-[0] pt-[10px] w-full bg-[#02130E] z-[150] left-[50%] translate-x-[-50%]"
            :class="{
                'ml-[122px] w-[80%]' : screenWidth > 1300,
                'ml-[30px] w-[85%]' : screenWidth < 1300,
            }"    
        />
        <div class="mt-[50px]">
            <div class="flex justify-center items-center mt-4 select-none">
                <div>
                    <img @click="prevMonth" src="../assets/left_arrow.svg" class="cursor-pointer h-[14px]">
                </div>
                <div class="mx-2 w-[150px] text-[#FFF] text-[14px] text-center font-semibold relative top-[2px]">
                    {{ currentMonthParsed.toUpperCase() }} {{ currentYear }}
                </div>
                <div>
                    <img @click="nextMonth" src="../assets/right_arrow.svg" class="cursor-pointer h-[14px]">
                </div>
            </div>
        </div>
        <div 
            class="h-full overflow-block overflow-x-auto"
            :class="{
                'w-full' : screenWidth > 1410,
                'w-[80vw]' : screenWidth <= 1410 && screenWidth > 1300,
                'w-[90vw]' : screenWidth <= 1300,
            }"    
        >
            <div class="w-[1190px] mx-auto  flex justify-center">
                <CalenarComponent 
                    :days = "calendarParsedDays"
                    :unparsedDays = "calendarUnparsedDays"
                    @viewFull="viewFull($event)"
                    class="w-[1190px] overflow-x-auto"
                />
            </div>
        </div>
    </div>
    <div v-else>
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute top-[50%] left-[50%] ml-[30px]"></div>
    </div>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'
    import CalenarComponent from '../components/Calendar/CalenarComponent.vue'
    import FullDayView from '../components/Calendar/FullDayView.vue'
    import FullTrainingView from '../components/Calendar/FullTrainingView.vue'
    import Header from '../components/Header.vue'
    import { getPlanedTrainingsByGroupId, getCompletedTrainingsByGroupId } from '../services/apiRequests'
    import { useMainStore } from '../store/mainStore'

    export default {
        name: 'CalendarPage',

        setup() {
            const currentDate = new Date()
            const currentYear = ref(currentDate.getFullYear())
            const currentMonth = ref(currentDate.getMonth())
            const currentMonthParsed = ref(currentDate.toLocaleString('uk-UA', { month: 'long' }))
            const calendarParsedDays = ref([])
            const calendarUnparsedDays = ref([])
            const newTrainingFormFlag = ref(false)
            const currentNewTrainingDate = ref(null)
            const allTrainingsRef = ref([])
            const trainingToView = ref({})
            const showFullTrainingFlag = ref(false)
            const completedTrainings = ref([])

            const screenWidth = ref(window.innerWidth)
            const fullDayViewFlag = ref(false)
            const fullDayViewDay = ref(null)

            const store = useMainStore()
            const loading = ref(false)

            onBeforeMount(async () => {
                console.log('before mount')
                const studentRes = await store.getStudentData()
                console.log(studentRes)
                if (!studentRes) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                    return
                }
                await store.getGroupData()
                await store.getTrainerData()
                await store.getStudentIncomes()
                // await store.getBalanceState()
                await store.getTelegramBotSubscribed()
                console.log('store.student.group_id ----- ', store.student.group_id.length > 0)
                if (store.student.group_id.length > 0) {
                    await store.getPlanedTrainings()
                    await store.getAllGroupStudents()
                }
                await store.getStudentMessages()
                await store.getStudentBodyParameters()
                await store.getStudentCompletedTrainings()
                loading.value = true

                const resCompletedTrainings = await getCompletedTrainingsByGroupId(store.student.group_id)
                console.log('resCompletedTrainings -------- ', resCompletedTrainings)
                completedTrainings.value = resCompletedTrainings.data
                await updateTrainings()

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                    console.log(screenWidth.value)
                });
            })

            const viewFull = (day) => {
                console.log(day)
                fullDayViewFlag.value = true
                fullDayViewDay.value = day
            }

            const getWeekDayId = (dayName) => {
                switch (dayName) {
                    case 'пн':
                        return 1
                    case 'вт':
                        return 2
                    case 'ср':
                        return 3
                    case 'чт':
                        return 4
                    case 'пт':
                        return 5
                    case 'сб':
                        return 6
                    case 'нд':
                        return 7
                }
            }

            const getDays = (allTrainings) => {
                const lastDayInMonth = new Date(currentYear.value, currentMonth.value + 1, 0).getDate()
                
                const days = []

                for (let i = 1; i <= lastDayInMonth; i++) {
                    const currentDayDate = new Date(currentYear.value, currentMonth.value, i)
                    const dayName = currentDayDate.toLocaleString('uk-UA', { weekday: 'short' })
                    days.push({
                        dayName: dayName,
                        weekDayId: getWeekDayId(dayName),
                        dayNumber: i,
                        dateObj: currentDayDate,
                        trainings: allTrainings?.filter(training => new Date(training.date).toISOString().split('T')[0] === currentDayDate.toISOString().split('T')[0])
                    })
                }

                return days
            }

            const parseWeeksToCalendar = (allTrainings) => {
                const calendarDaysFull = new Array(42).fill(null)
                const currentMonthDays = getDays(allTrainings)
                console.log('currentMonthDays ------ ', currentMonthDays[0])
                let startMonthDay = 30 - (currentMonthDays[0].weekDayId - 2) 
                for (let i = 1; i <= 6; i++) {
                    for (let j = 1; j <= 7; j++) {
                        const currentDay = currentMonthDays[0]
                        if (currentDay?.weekDayId == j) {
                            calendarDaysFull[(i - 1) * 7 + j - 1] = currentDay
                            if (currentDay.dayNumber == new Date().getDate() && currentDay.dateObj.getMonth() == new Date().getMonth() && currentDay.dateObj.getFullYear() == new Date().getFullYear()) {
                                calendarDaysFull[(i - 1) * 7 + j - 1].is_current = true
                            } else {
                                calendarDaysFull[(i - 1) * 7 + j - 1].is_current = false
                            }
                            currentMonthDays.shift()
                            startMonthDay = 1
                        } else {
                            calendarDaysFull[(i - 1) * 7 + j - 1] = {
                                type: 'empty',
                                month_day: startMonthDay
                            }
                            startMonthDay++
                        }
                    }
                }

                return calendarDaysFull
            }

            const nextMonth = () => {
                if (currentMonth.value == 11) {
                    currentMonth.value = 0
                    currentYear.value++
                } else {
                    currentMonth.value++
                }
                currentMonthParsed.value = new Date(currentYear.value, currentMonth.value, 1).toLocaleString('uk-UA', { month: 'long' })
                calendarParsedDays.value = parseWeeksToCalendar(allTrainingsRef.value)
                calendarUnparsedDays.value = getDays(allTrainingsRef.value)
            }

            const prevMonth = () => {
                if (currentMonth.value == 0) {
                    currentMonth.value = 11
                    currentYear.value--
                } else {
                    currentMonth.value--
                }
                currentMonthParsed.value = new Date(currentYear.value, currentMonth.value, 1).toLocaleString('uk-UA', { month: 'long' })
                calendarParsedDays.value = parseWeeksToCalendar(allTrainingsRef.value)
                calendarUnparsedDays.value = getDays(allTrainingsRef.value)
            }

            const updateTrainings = async () => {
                const trainingsRes = await getPlanedTrainingsByGroupId(store.student.group_id)
                const completedTrainingsRes = await getCompletedTrainingsByGroupId(store.student.group_id)
                console.log('trainingsRes ------ ', completedTrainingsRes)
                allTrainingsRef.value = [...trainingsRes.data, ...completedTrainingsRes.data]
                calendarParsedDays.value = parseWeeksToCalendar(allTrainingsRef.value)
                calendarUnparsedDays.value = getDays(allTrainingsRef.value)

                fullDayViewDay.value?.trainings.forEach((training, id) => {
                    const currentTraining = trainingsRes.data.find(t => t.training_id === training.training_id)
                    if (currentTraining) {
                        fullDayViewDay.value.trainings[id] = currentTraining
                    }
                })

                trainingsRes.data.forEach((training, id) => {
                    if (new Date(training.date).toISOString().split('T')[0] === fullDayViewDay.value?.dateObj.toISOString().split('T')[0] && !fullDayViewDay.value.trainings.find(t => t.training_id === training.training_id)) {
                        fullDayViewDay.value.trainings.push(training)
                    }
                })
            }

            return {
                currentYear,
                currentMonth,
                currentMonthParsed,
                calendarParsedDays,
                calendarUnparsedDays,
                newTrainingFormFlag,
                currentNewTrainingDate,
                allTrainingsRef,
                trainingToView,
                showFullTrainingFlag,
                fullDayViewFlag,
                fullDayViewDay,
                nextMonth,
                prevMonth,
                viewFull,
                loading,
                screenWidth
            }
        },

        components: {
            CalenarComponent,
            FullDayView,
            FullTrainingView,
            Header
        }
    }
</script>

<style lang="scss" scoped>

    .overflow-block::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .overflow-block::-webkit-scrollbar-track {
        background: #02130E;        /* цвет зоны отслеживания */
    }

    .overflow-block::-webkit-scrollbar-thumb {
        background-color: #A1D9F7;    /* цвет бегунка */
        border-radius: 20px;
    }

</style>