<template>
    <div class="px-5 absolute top-[50%] translate-y-[-50%]">
        <div class="text-center text-[#FFF] text-[21px] font-semibold">Ви будете перенаправлені на сторінку оплати</div>
        <div class="text-center text-[#FFF] text-[12px] mt-2">Після оплати можете повернутись в свій кабінет</div>
        <button @click="$router.push('/')" class="bg-[#5AC47D] mt-4 rounded-[9px] text-white px-3 py-2 w-full text-base font-semibold flex items-center align-middle justify-between">
            <div class="w-full">В кабінет</div>

            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
            </svg>

        </button>
    </div>
</template>

<script>
    import { onBeforeUnmount } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'

    export default {
        name: 'PaymentRedirect',

        setup() {
            const store = useMainStore()

            onBeforeUnmount(async () => {
                await store.getBalanceState()
                await store.getStudentIncomes()
            })
        }
    }
</script>
