<template>
    <div class="pt-[20px]">
        <div class=" w-full h-[230px] relative">
            <div class="flex items-end absolute justify-start w-[100%] h-[230px] z-[100]">
                <div :key="wallpaperKey" class="w-[200px] h-[230px] flex items-end justify-center overflow-hidden">
                    <img v-if="store.student.wallpaper_photo?.length > 0" :src="store.studentPhoto" class="object-contain h-full">
                    <img v-else src="../../assets/student_photo_placeholder.svg" class="object-contain h-[80%] absolute bottom-0">
                </div>
                <img 
                    @click="uploadPhoto"
                    src="../../assets/upload_icon.svg" 
                    class="absolute left-[10px] top-[200px] cursor-pointer z-40"
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="loadImage($event)"
                />
            </div>    

            <!-- <div class="text-center absolute right-[6vw] top-[40px] flex flex-col justify-center align-middle items-center">
                <div class="text-[#CA3F79] text-center">
                    <span class="font-bold text-xl">{{ store.studentBalance.toFixed(2) }}</span> <span>грн.</span>
                </div>
                <div class="text-white text-center text-[10px] mt-1">
                    активний до <b>{{ activeToDate }}</b>
                </div>
                <div class="mt-8">
                    <div class="text-center text-[#A1D9F7] mb-[-60px]"><div class="font-bold text-[18px]">{{ store.trainingsAttendedProcent ? store.trainingsAttendedProcent.toFixed(0) : 0 }}%</div><div class="text-[8px] mt-[-3px]">відвідування</div></div>
                    <circle-progress :fillColor="'#A1D9F7'" :emptyColor="'rgba(235, 235, 235, 0.1)'" :size="90" :percent="store.trainingsAttendedProcent ? store.trainingsAttendedProcent : 0" :viewport="true" :borderWidth="12" :borderBgWidth="12" />
                </div>
            </div> -->

            <AdaptiveTextBlockNew
            
                class="absolute top-[-25px] w-full"
                :name="store.student.name_eng" 
                :surname="store.student.surname_eng" 
                :number="store.student.player_number"
            />

            <div class="absolute right-[10px] bottom-[44px]">
                <div class="flex items-end w-full" :class="{'justify-end' : store.group.payment_status == 'free'}">
                    <div class="flex items-center relative top-[3px] mr-[5px]">
                        <img src="@/mobileApp/assets/logo.svg" class="w-[20px] mr-[6px] relative left-[3px]">
                        <div class="mt] text-white ">
                            |
                        </div>
                        <div>
                            <img src="@/mobileApp/assets/ukraine_flag.svg" class="ml-[3px] w-[25px]">
                        </div>
                    </div>
                    <div v-if="store.group.payment_status == 'paid'">
                        <div 
                            class="text-end"
                            :class="{
                                'text-[#5AC47D]' : currentDate < dateActiveTo,
                                'text-[#CA3F79]' : currentDate > dateActiveTo
                            }"    
                        >
                            <span class="font-bold text-xl">{{ formatDate(store.student.date_active_to) }}</span>
                        </div>
                        <div class="text-white w-[115px] text-end text-[10px]">
                            тариф активний до
                        </div>
                    </div>
                </div>
                <div class="mt-[15px] relative" :class="{'w-[190px]' : store.group.payment_status == 'free'}">
                    <TrainingAttending class="training-attending-styles absolute right-0" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch, onMounted } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { useRouter } from 'vue-router'
    import { uuid } from 'vue3-uuid'
    import CircleProgress from "vue3-circle-progress"
    import AdaptiveTextBlockNew from './AdaptiveTextBlockNew.vue'
    import TrainingAttending from './TrainingAttending.vue'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'student-preview',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const fileInput = ref(null)
            const inputValue = ref(null)
            const wallpaperKey = ref(uuid.v4())

            const showTextRoll = ref(false)

            const activeToDate = ref(new Date().toLocaleDateString().split('T')[0].split('-').reverse().join('.'))

            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const uploadPhoto = () => {
                fileInput.value.click()
            }

            const currentDate = new Date()
            const dateActiveTo = new Date(store.student.date_active_to)

            const formatDate = (date) => {
                const currentDate = new Date(date)
                const day = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`
                const month = currentDate.getMonth() + 1 > 9 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`
                return `${day}.${month}.${currentDate.getFullYear()}`
            }

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        console.log('imageToCrop.value.src', blob)
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        store.imageToCrop = imageToCrop.value
                        router.push('/wallpaper-loader')
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            onMounted(() => {
                wallpaperKey.value = uuid.v4()
                const currentDate = new Date()

                const mountValue = currentDate.getMonth() > 9 ? currentDate.getMonth() : `0${currentDate.getMonth()}`
                const mountValue2 = currentDate.getMonth() + 2 > 9 ? currentDate.getMonth() + 2 : `0${currentDate.getMonth() + 2}`

                if (store.studentBalance >= 0) {
                    const currentDate = new Date()
                    activeToDate.value = `01.${mountValue2}.${currentDate.getFullYear()}`
                } else {
                    activeToDate.value = 'борг'
                }
            })

            watch(() => store.student.name_eng, (newValue) => {
                showTextRoll.value = true
            })

            return {
                store,
                fileInput,
                uploadPhoto,
                inputValue,
                loadImage,
                wallpaperKey,
                activeToDate,
                showTextRoll,
                formatDate,
                currentDate,
                dateActiveTo
            }
        },     

        components: {
            CircleProgress,
            AdaptiveTextBlockNew,
            TrainingAttending
        }
    }
</script>

<style lang="scss" scoped>
    .logo {
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.9));
    }

    .training-attending-styles {
        @apply w-[95%] mx-auto;
    }
</style>