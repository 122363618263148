<template>
    <button class="switch-button" :class="{ 'switch-on': isOn }" @click="toggleSwitch">
      <div class="switch-slider"></div>
    </button>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'SwitchButton',

    props: {
        isOnValue: {
            type: Boolean,
            required: true
        },

        width: {
            type: String,
            default: '50px'
        },
        
        height: {
            type: String,
            default: '35px'
        }
    },

    setup(props) {
      const isOn = ref(props.isOnValue);
  
      const toggleSwitch = () => {
        isOn.value = !isOn.value;
      };
  
      return {
        isOn,
        toggleSwitch
      };
    }
  };
  </script>
  
  <style scoped>
  .switch-button {
    position: relative;
    width: 32px;
    height: 20px;
    border-radius: 15px;
    border: solid 1px #42413f;
    cursor: pointer;
    outline: none;
  }
  
  .switch-on {
    background-color: #5AC47D;
  }
  
  .switch-slider {
    position: absolute;
    top: 1px;
    left: 1.8px;
    width: 16px;
    height: 16px;
    border-radius: 13px;
    background-color: #fff;
    transition: transform 0.3s ease;
  }
  
  .switch-on .switch-slider {
    transform: translateX(11px);
  }
  </style>
  