<template>
    <div v-if="loading" class="main-page">
        <Header 
            class="fixed top-[10px] left-[50%] translate-x-[-50%]"
            :class="{
                'ml-[122px] w-[80%]' : screenWidth > 1300,
                'ml-[30px] w-[85%]' : screenWidth < 1300,
            }"    
        />
        <div v-if="screenWidth > 1800" class="max-w-[1535px] relative mx-auto mt-[35px]">
            <StudentWithTrainingsBar 
                class="absolute left-[50%] top-[28.5%] ml-[43px] translate-x-[-50%] z-40  border-red-400"
            /> 
            <AdaptiveTextBlockNew 
                :name="store.student.name_eng"
                :surname="store.student.surname_eng"
                :number="store.student.player_number"
                class="select-none mt-[23px] relative w-[100%]"
            />
            <div class="w-full flex justify-between">
                <div class="w-[580px]">
                    <StudentBasicData class="w-full px-[24px] mt-[75px] bg-[#A1D9F7] rounded-[8px] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20"/>
                    <StudentFinanceData v-if="store.group.payment_status == 'paid'" :lastIncome="lastIncome" class="w-full px-[10px] mt-[75px] bg-[#A1D9F7] rounded-[8px] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20"/>
                </div>
                <div>
                    <StudentParametersBlock class="mt-[45px]"/>
                </div>
            </div>
            <div class="w-full mt-[53px] flex justify-between  border-green-500">
                <StudentMessagesBlock :width="'590px'"/>
                <TrainingCalendar 
                    :groupId="store.group?.group_id"
                    :trainingsAttendedList="store.studentAttendedTrainings"
                    :trainingsMissedList="store.studentMissedTrainings"
                    class="mt-[-13px]"
                />
                <TrainingsBlock />
            </div>
        </div>
        <div v-else class="max-w-[1200px] mt-[48px] mb-[60px] relative mx-auto">
            <div class="mx-auto">
                <AdaptiveTextBlockNew 
                    :name="store.student.name_eng"
                    :surname="store.student.surname_eng"
                    :number="store.student.player_number"
                    class="select-none w-[100%] relative"
                    :class="{
                        'right-[-15px]' : screenWidth < 1400,
                    }"
                />
                <div class="w-full h-[333px] flex relative justify-between">
                    <div class="w-[580px] flex flex-col pt-[90px] pb-[20px] justify-between">
                        <StudentBasicData class="w-full ml-[35px] rounded-[8px] bg-[#A1D9F7] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20"/>
                        <StudentFinanceData v-if="store.group.payment_status == 'paid'" :lastIncome="lastIncome" class="ml-[37px] bg-[#A1D9F7] rounded-[8px] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20"/>
                    </div>
                    <StudentWithTrainingsBar 
                        photoHeight="507px"
                        photoWidth="385px"
                        class="z-40 relative top-[-220px] border-red-400"
                        :class="{
                            'right-[50px]' : screenWidth < 1400,
                            'right-[100px]' : screenWidth >= 1400
                        }"
                    /> 
                </div>
                <div 
                    class="ml-[33px] flex justify-between mt-[20px] border-green-500"
                    :class="{
                        'w-[89%]' : screenWidth < 1400,
                        'w-[86%]' : screenWidth >= 1400
                    }"    
                >
                    <StudentMessagesBlock width="600px" class=""/>
                    <TrainingsBlock class=""/>
                </div>
                <div 
                    class="flex justify-between mt-[20px]"
                    :class="{
                        'w-[90%]' : screenWidth >= 1400,
                        'w-[93%]' : screenWidth < 1400
                    }"
                >
                    <StudentParametersBlock class="ml-[30px]"/>
                    <TrainingCalendar 
                        :groupId="store.group?.group_id"
                        :trainingsAttendedList="store.studentAttendedTrainings"
                        :trainingsMissedList="store.studentMissedTrainings"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="spinner"></div>
    </div>
</template>

<script>
    import { ref, watch, onMounted, computed, onBeforeMount } from 'vue'
    import { useMainStore } from '../store/mainStore'
    import { useRouter } from 'vue-router'

    import StudentWithTrainingsBar from '../components/StudentPreview/StudentWithTrainingsBar.vue'
    import AdaptiveTextBlockNew from '../components/StudentPreview/AdaptiveTextBlockNew.vue'
    import StudentBasicData from '../components/StudentPreview/StudentBasicData.vue'
    import StudentFinanceData from '../components/StudentPreview/StudentFinanceData.vue'
    import StudentParametersBlock from '../components/StudentPreview/StudentParametersBlock.vue'
    import StudentMessagesBlock from '../components/StudentPreview/StudentMessagesBlock.vue'
    import TrainingCalendar from '../components/StudentBasicInfo/TrainingCalendar.vue'
    import TrainingsBlock from '../components/StudentPreview/TrainingsBlock.vue'
    import Header from '../components/Header.vue'

    export default {
        name: 'main-page',

        setup() {
            const store = useMainStore()
            const loading = ref(false)
            const router = useRouter()
            const screenWidth = ref(window.innerWidth)

            const lastIncome = ref(null)

            watch(() => window.innerWidth, (val) => {
                screenWidth.value = val
            })

            onBeforeMount(async () => {
                console.log('before mount')
                const studentRes = await store.getStudentData()
                console.log(studentRes)
                if (!studentRes) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                    return
                }
                await store.getGroupData()
                await store.getTrainerData()
                await store.getStudentIncomes()
                // await store.getBalanceState()
                await store.getTelegramBotSubscribed()
                await store.getStudentIncomes()
                console.log('store.student.group_id ----- ', store.student.group_id.length > 0)
                if (store.student.group_id.length > 0) {
                    await store.getPlanedTrainings()
                    await store.getAllGroupStudents()
                }
                await store.getStudentMessages()
                await store.getStudentBodyParameters()
                await store.getStudentCompletedTrainings()
                loading.value = true

                lastIncome.value = store.studentIncomes[store.studentIncomes.length - 1] 

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                    console.log(screenWidth.value)
                });
            })

            return {
                store,
                loading,
                screenWidth,
                lastIncome
            }
        },

        components: {
            StudentWithTrainingsBar,
            AdaptiveTextBlockNew,
            StudentBasicData,
            StudentFinanceData,
            StudentParametersBlock,
            StudentMessagesBlock,
            TrainingCalendar,
            TrainingsBlock,
            Header
        }
    }
</script>

<style lang="scss" scoped>
    .preview-container {
        @apply h-[73vh] relative;
    }

    .preview-styles {
        @apply w-full h-full absolute;
    }

    .basic-info-styles {
        @apply h-[40%];
    }

    .spinner {
        @apply w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute top-[50%] left-[50%] ml-[30px];
    }
</style>